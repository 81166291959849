<template>
<faq1 accroche="Questions & réponses"
			date="12/09/2021"
			auteur="Nicolat L."
			fonction="Analyste immobilier."
			avatar="1846"
			comments=""
			like=""
			:xml="selected_xml"
			authorinfo="" />
</template>


<script>
import faq1 from "@/components/faq1.vue";

export default {
	name: 'questions',
	components: {
		faq1
	},
	props: {

	},
	data: () => ({
		selected_xml: "",

		xml_en: `  <?xml version="1.0"?>
		<questions>
		<titre>The answers to your questions		</titre>
		<soustitre>The answers to your questions		</soustitre>

<illustration>https://media.berlcoin.fr/5240324.jpg</illustration>

<category>

		<libelle>Blockchain</libelle>
		<cat_id>BC</cat_id>



		<question>
	<reference>B1</reference>
	<libelle>What is a blockchain</libelle>
	<reponse>A blockchain is a decentralized public ledger that attests to who holds what.
	Blockchains allow value to be stored and exchanged over the internet without a centralized intermediary.
	They are the technological engine of cryptocurrencies, the Decentralized Web and decentralized finance.
	</reponse>
	</question>
<question>
	<libelle>What is Stellar? </libelle>
		<reference>B11</reference>
		<reponse>Stellar is both a decentralized exchange protocol and a decentralized payment protocol based on the blockchain. It allows for easy and cost-effective bridging between tokens and different FIAT currencies (euro, dollar, yuan...).</reponse>
		</question>



			<question>
			<reference>B3</reference>
			<libelle>What is a digital asset ?</libelle>
			<reponse>
			A digital asset is a binary format intangible asset that includes a property right.
			</reponse>
			</question>

 <question>
 <reference>B4</reference>
 <libelle>What is a token</libelle>
 <reponse>
 A token refers to a digital asset that is transferable between two parties without the need for third-party authorization. It is a basic instrument of decentralized finance.
 </reponse>
 </question>

 	<question>
 	<reference>B5</reference>
 	<libelle>Is a token a cryptocurrency?</libelle>
 	<reponse>
 	No because a token does not have its own Blockchain but relies on a cryptocurrency to validate its transactions.
 	</reponse>
 	</question>

 		<question>
 		<reference>B6</reference>
 		<libelle>What is a berlcoin? </libelle>
 		<reponse>
 		A berlcoin is a token on the Stellar blockchain.
 		</reponse>
 		</question>

 			<question>
 			<reference>B14</reference>
 			<libelle>What is a Lumens? </libelle>
 			<reponse>
 			Lumens is the cryptocurrency of the Stellar blockchain, it is used to pay transaction fees.
 			</reponse>
 			</question>


 			<question>
 			<reference>B13</reference>
 			<libelle>What is an fcEURO</libelle>
 			<reponse>
 			An fcEURO is a token of the Stellar blockchain that is issued by the company Fraction.re. It is a unit of account used in the Fraction.r ecosystem of which Berlcoin is a part.  Its price is fixed at 1 fcEURO = 1 EURO. This token can only be used to buy or sell Berlcoins or tokens from the Fraction.re ecosystem
 			</reponse>
 			</question>




  <question>
  <reference>B7</reference>
  <libelle>What is an NFT</libelle>
  <reponse>
  An NFT "Non fungible token" is a unique token that is non-fungible, and therefore non-cuttable.
  NFTs are cryptographic elements on the blockchain with unique identification codes and metadata (author, signature, date, type...) that distinguish them from each other.
  Each NFT corresponds to a specific property.
  </reponse>
  </question>

  <question>
    <reference>B8</reference>
    <libelle>What is a stablecoin? </libelle>
    <reponse>
    A stablecoin is a digital asset that replicates the value of another asset, currency or external reference.
    Its price can be pegged to the price of assets such as gold, oil, gas, diamonds or the value of a government issued currency such as the dollar, euro, yuan, etc....
    The fcEURO can be considered a stablecoin replicating the value of euros and the BERLCOIN as a stablecoin replicating the value of real estate assets.
    </reponse>
    </question>
  	<question>
   	  <reference>B9</reference>
   	  <libelle>What is a Smart Contract? </libelle>
   	  <reponse>
   	  Smart Contracts are based on a computer protocol to verify or enforce a mutual contract. They are deployed and publicly searchable in a blockchain.
   	  </reponse>
   	  </question>

  		<question>
  			  <reference>B10</reference>
  			  <libelle>What is a Wallet? </libelle>
  			  <reponse>
  			  A wallet is a secure physical or digital storage process for tokens, bank cards or cryptocurrencies.
  			  The Berlcoin Wallet is one of the wallets you can use that is specifically designed for Berlcoin.  But it is not a requirement, you can use any Wallet.
  			  </reponse>
  			  </question>

   <question>
   <reference>B11</reference>
   	<libelle>What is a Fraction? </libelle>
   	<reponse>
   A fraction is a share of real estate in a French law contract of future income assignment. The Berlcoin token is the representation in the blockchain of this fraction.
   The value of a fraction is identical to the value of a Berlcoin.
   	</reponse>
   </question>


	 <question>
	 <reference>B15</reference>
<libelle>Do I have to use blockchain to invest?</libelle>
<reponse>
No, you can fill in the KYC/AML credentials on https://wallet.fraction.re/identity and invest in the primary market.
Your investment will of course be registered in the blockchain and you will receive your keys to verify, but you are not required to claim your tokens.  If you want to reinvest, you will be able to use the public key, but also your email or your phone to identify yourself.
To resell, just send us an email or use the form at https://coffee.berlcoin.berlin/market.html
On the other hand, to use the decentralized markets, the AMM or the investments in the Liquidity Pool, the sponsoring or more generally all the tools specific to the blockchain, you will have to use the blockchain.
</reponse>
	 </question>

   </category>

  			 	  <category>

  			 	  <libelle>Function</libelle>
  			 	  <cat_id>FT</cat_id>
  			 	  <question>
  			 	  <reference>F1</reference>
  			 	  <libelle>What is the berlcoin platform? </libelle>
  			 	  <reponse>
  			 	  Berlcoin.berlin is a platform that specializes in real estate. It allows users to invest in real estate in a mutualized way and uses blockchain and tokens to secure investments.

  			 	  The berlcoin is the unit of account used to define the share held by each investor.

  			 	  The operating principle is to create a non-fungible token (NFT) for each real estate property and berlcoin tokens guaranteed by these NFTs.

  			 	  The set of NFTs guarantee the value of the berlcoin tokens.

  			 	  </reponse>
  			 	  </question>


   		  <question>
   		  <reference>F2</reference>
   		  <libelle>How is the reference value of berlcoin determined?</libelle>
   		  <reponse>

				With the latest index published by the association of German banks (https://www.pfandbrief.de/site/de/vdp/immobilie/finanzierung_und_markt/vdp-immobilienpreisindex.html).
		 	Take the column Selbst genutztes Wohneigentum > Eigentums-wohnungen > Condominiums (Column E)
		 	Take the line corresponding to the last quarter, the reference value on the 1st day of the following quarter is this figure multiplied by 0.37387.
		 	The growth index is that of column H, calculated by taking the value of column E - value of column E of the same quarter in the previous year ) divided by value of column E of the same quarter in the previous year.

		 	In the 3rd quarter of 2021, the index is 267.5.  Multiplied by 0.37387, Berlcoin = 100 euros on October 1, 2021, the growth index is 10% (column H)
		 	In the 4th quarter of 2021, the index is 273.1.  Multiplied by 0.37387, Berlcoin = 102.12 euros on January 1, 2022, the growth index is 11.5% (column H)
		 	In the 1st quarter of 2022, the index is 282.7.  Multiplied by 0.37387, Berlcoin = 105.69 euros on April 1, 2022, the growth index is 13.3% (column H)

	 		  </reponse>
   		  </question>

      <question>
      <reference>F3</reference>
      <libelle>How is the price of berlcoin determined?</libelle>
      <reponse>
      berlcoin is a token that can be bought and sold on marketplaces: its price is therefore determined based on supply and demand.
      </reponse>
      </question>

    		  <question>
    		  <reference>F4</reference>
    		  <libelle>How is the value of an NFT in berlcoin calculated?</libelle>
    		  <reponse>
    		  When it is created, the value of an NFT corresponds to its value of ownership for 10 years: the properties are intended to be resold in the 11th year and the NFT is then destroyed.

    		  The value of the NFT is calculated by adding the acquisition price, taxes, notary fees, estimated expenses for the 10 years of ownership and a provision of 250 euros per m2 for a refurbishment before resale. If necessary, intermediary fees (real estate agent, lawyer, expert...) or renovation and purchase of furniture for the rental are added.

    		  This amount is divided by the reference value of the berlcoin at the time of the creation of the NFT: we thus obtain the number of berlcoins guaranteed by this NFT.
    		  </reponse>
    		  </question>

       <question>
       <reference>F5</reference>
       <libelle>How is the value of an NFT in euros calculated? </libelle>
       <reponse>
       This is the initial value of the NFT discounted by applying the growth variation calculated with the VDP Immobilienpreisindex.
        </reponse>
       </question>

     	  <question>
     	  <reference>F6</reference>
     	  <libelle>Is the collateral for an NFT in berlcoin fixed?</libelle>
     	  <reponse>
     	  Yes, when the NFT is created, the number of berlcoins corresponding to its value is permanently fixed: so there is no risk of diluting the collateral.
     	  </reponse>
     	  </question>

     		  <question>
     		  <reference>F8</reference>
     		  <libelle>Can I resell my berlcoins whenever I want to. </libelle>
     		  <reponse>
     		  Yes for those that are not embedded in a term investment contract. If your berlcoins are placed in a term contract, they can only be resold once the term has expired.
     		  </reponse>
     		  </question>

     			  <question>
     			  <reference>F11</reference>
     			  <libelle>Where can you buy and sell berlcoins with a guaranteed price? </libelle>
     			  <reponse>
     			  On our marketplace (the primary market or guaranteed price marketplace). The price is set by taking the total value of the NFT portfolio divided by the number of tokens issued.
     			  </reponse>
     			  </question>


        <question>
        <reference>F12</reference>
        <libelle>Where can you buy and sell berlcoins at market price. </libelle>
        <reponse>
        On any marketplace (decentralized markets) accepting tokens on the stellar blockchain or at the ERC20 standard on the ethereum blockchain. And on AMMs (Automated Market Maker) against other tokens or cryptocurrencies.
        </reponse>
        </question>

      	  <question>
      	  <reference>F13</reference>
      	  <libelle>Do we have a right of first refusal on an NFT when selling a property?</libelle>
      	  <reponse>
      	  Yes for asset-based contracts. For others, berlcoin holders will obviously have priority.
      	  </reponse>
      	  </question>

      		  <question>
      		  <reference>F14</reference>
      		  <libelle>Can we buy and sell berlcoins in cryptocurrencies?</libelle>
      		  <reponse>
      		  Yes, on any marketplace that accepts tokens on the stellar blockchain or at ERC20 standard on the ethereum blockchain. The price is the market price and not the reference value.
      		  Our guaranteed price marketplace is exclusively in euros.
      		  </reponse>
      		  </question>

      			  <question>
      			  <reference>F15</reference>
      			  <libelle>Can you keep your berlcoins in a wallet or in a safe deposit box?</libelle>
      			  <reponse>
      			  Yes, berlcoins are tokens that you can transfer them easily.
      			  Be careful though, you won't be able to resell them on the marketplace at a guaranteed price if they were transferred to an address on which we couldn't do KYC checks.
      			  </reponse>
      			  </question>

         <question>
         <reference>F16</reference>
         <libelle>Can we convert our Berlcoins for the ethereum network?</libelle>
         <reponse>
         Yes, Berlcoins are stellar tokens but you can buy ERC20 Berlcoins in exchange for a native Berlcoin.
         These native Berlcoins are then placed in an escrow account, so the 1:1 parity is guaranteed.
         Gas fees are on both networks: one stellar transaction + one ethereum transaction.
         </reponse>
         </question>

       	  <question>
       	  <reference>F17</reference>
       	  <libelle>What is the primary market. </libelle>
       	  <reponse>
       		This is the marketplace used by the property manager to sell newly issued berlcoins when acquiring a property or for the sale of undistributed or returned berlcoin.
       		The selling price is fixed, corresponding to the reference value on the day of the selling order.
       	  </reponse>
       	  </question>

       		<question>
       		  <reference>F18</reference>
       	 <libelle>What is the decentralized market? </libelle>
       	 <reponse>
       	 It is the marketplace used by Internet users to buy and sell Berlin.
       	The selling price is free, it is set by supply and demand. Each player can place a buy or sell order at the price he wants, this order is registered in the order book and these orders are executed chronologically.
       	The order is put on hold as long as it has not found a buyer and can be modified or cancelled as long as it has not been executed. The price is therefore guaranteed, but not the time frame.
       		</reponse>
       		</question>

       		<question>
       	  <reference>F19</reference>
       		 <libelle>What is an automatic market (AMM)?</libelle>
       		 <reponse>
       		 It is the marketplace used by Internet users for immediate buying and selling of Berlcoin.
       		The buying and selling price is calculated by a smart-contract according to the liquidity in Liquidity Pool. There is no price guarantee, but the order is executed immediately.
       		The cost of using AMM is 0.3%.
       		</reponse>
       	</question>

				<question>
			 	      <reference>F20</reference>
			 <libelle>How to resell without using the blockchain</libelle>
			 <reponse>
			 You can use the primary market to resell without going through the blockchain as long as you initially invested via the primary market and did not transfer your tokens.

			 Go to https://berlcoin.market > primairy market > sell.
			 You will receive an amendment to your contract, the berlcoins will be resold on the primary market and you will receive a bank transfer to the bank account you used for the purchase.
 				</reponse>
			 	</question>



       		  </category>

 		<category>
 		 <libelle>Company</libelle>
 		<cat_id>SO</cat_id>
 		<question>
 		<reference>S1</reference>
 		<libelle>What company runs the berlcoin?</libelle>
 		<reponse> Fraction.re SAS is a company that manages berlcoin. It has its headquarters at 56, rue Monge 75005 Paris, France. Fraction.re is a Société par Actions Simplifiée with a minimum capital of 250,000 euros registered on 30/12/2021 at the Paris Trade and Companies Registry under number 908 680 002.
 		</reponse>
 		</question>

 		 <question>
 		 <reference>S2</reference>
 		 <libelle>What company manages the real estate? </libelle>
 		 <reponse>
 		  SCI CONDE 52 is the company that owns the first 3 properties that were initially put up as collateral. It is a civil real estate company that has its headquarters 2Ter rue de Bapaume 59000 Lille, France. It is registered with the Lille Trade and Companies Registry under number 494 556 863 since 2007.

 		  Fraction Real Estate SAS is the company that administers the properties, it has its headquarters at 56, rue Monge 75005 Paris, France, it is a Société par Actions Simplifiée à capital variable registered on 01/04/2022 at the Registre du Commerce et des Sociétés de Paris under number 911 774 206.

 		  </reponse>
 		 </question>

 		</category>
 <category>


         <libelle>Management</libelle>
         <cat_id>GT</cat_id>

         <question>
         <reference>G1</reference>
         <libelle>How does Fraction.re SAS pay for itself</libelle>
         <reponse>
				 This company is the first investor for each property purchased.

			 			Its share of investment is made through its own funds. It receives a number of tokens corresponding to these investments at the same title and price as any other investor.

			 			At the end of a holding cycle, the company resells the property and buys back the number of guaranteed Berlcoin at the reference value.

			       	 We also have two kinds of income:

			 We keep 1% of tokens in circulation each year, provided there are enough undistributed tokens. (we never issue new tokens without purchasing real estate collateral)

			 We benefit from the revenues generated by decentralized finance (Defi), such as AMM for example.

			 To simplify: real estate for assets, Defi for revenue streams.

			 This is the strength of the Berlcoin economic model: the interests of all the players are strictly identical.


         </reponse>
         </question>


				 			      <question>
				 			      <reference>G12</reference>
				 			      <libelle>What risks and benefits does Fraction.re SAS take  ?</libelle>
				 			      <reponse>
										At the end of a holding cycle, the company resells the property and buys back the number of Berlcoin backed by that property at the base value.

										 If the actual resale price is higher than the price of the number of Berlcoin the company must buy back to remove the property from the collateral portfolio, it makes a profit.

										 Conversely, if the actual resale price is lower, it loses money. So, to make sure they sell at a price that makes them money, they are very careful about what they buy.

										 This is a very strong guarantee for investors, because the portfolio of goods always has a value at least equal to the value of the Berlcoins.

										 This is also the strength of the Berlcoin business model: the interests of some players reinforce the protection of others.
				 			      </reponse>
										</question>


													<question>
										      <reference>G13</reference>
										      <libelle>What is the origin of the 1% retained annually ?</libelle>
										      <reponse>We keep 1% of tokens outstanding each year, provided there are enough undistributed tokens. (we never issue new tokens without purchasing real estate collateral)
			These are tokens that have not been distributed after their issuance.
When a part of the financing comes from the income linked to the assets themselves, independently of their sources, the tokens issued corresponding to this financing are not distributed since their financing remains internal to the company. However, they constitute a reserve that reinforces the company's assets.

This 1% is extracted from this reserve fund (if there is any available), and the berlcoins can therefore be resold for our own account.


										      </reponse>
										      </question>

         <question>
         <reference>G2</reference>
         <libelle>What are the rental management fees?</libelle>
         <reponse>None, they are fully paid for by the company that manages Berlcoin.
         </reponse>
         </question>

         <question>
         <reference>G3</reference>
         <libelle>What are the rental insurance costs?</libelle>
         <reponse>There is no rental vacancy or unpaid insurance as this risk is very low on the selected properties.
         </reponse>
         </question>

         <question>
         <reference>G4</reference>
         <libelle>What are the fees for buying or selling berlcoin tokens?</libelle>
         <reponse>A gas fee for registering a transaction in the blockchain is taken by the network or miners. These fees vary depending on the time and speed of recording the transaction.   They are less than 1 cent. During automatic exchange on an AMMs the fees are 0.3% of the exchanged token.
         </reponse>
         </question>


           <question>
           <reference>G5</reference>
           <libelle>What are the fees charged by when buying futures contracts?</libelle>
           <reponse>
          In Lumens: none
          By SEPA transfer: none
           By bank card or paypal: between 1.6% and 3.4% depending on the card or bank
           </reponse>
           </question>
           <question>
           <reference>G11</reference>
           <libelle>What are the fees charged by when selling berlcoins?</libelle>
           <reponse>
           Gas fee ( between 0.01 and 0.03 euros ) on the primary market.
           Gas charges ( between 0.01 and 0.03 euros ) on the decentralized markets not automated.
           On the AMM (Automated Market Maker) Stellar : 0.3%.
           On the uniswap market in ether: 0.3%
           </reponse>
           </question>

          <question>
           <reference>G6</reference>
           <libelle>What yield can be achieved? </libelle>
           <reponse>
          The reference value of Berlcoin is calculated according to the VDP Immobilienpreisindex: 11.5% in 2021, 9.6% on average over the last 5 years.

          In addition, you can get free Berlcoin via :

          - Futures: 2 years +1.9%, 3 years +5.6%, 5 years +14.4%, 7 years +28.5%, 10 years +46% to 55%

          - Decentralized finance: 0.3% of transactions

          - Sponsorship: 2% for the sponsor, 1% for the sponsee + the percentage of the term contract.
           </reponse>
           </question>

           <question>
           <reference>G7</reference>
           <libelle>Can we invest via an SCI or a company. </libelle>
           <reponse>Yes, as long as the beneficial owners are identifiable.</reponse>
           </question>



           <question>
           <reference>G8</reference>
           <libelle>Is there a free token issue for executives?</libelle>
           <reponse>No </reponse>
           </question>


           <question>
           <reference>G9</reference>
           <libelle>What is the recommended shelf life of tokens?</libelle>
           <reponse> None, the advantage of tokenization is precisely that you can buy and sell without delay with reduced fees. </reponse>
           </question>


           <question>
           <reference>G10</reference>
           <libelle>What is the recommended duration for term investments?</libelle>
           <reponse>The longer the contract, the more tokens it will generate at its end. Therefore, the 10-year contract is the most financially interesting investment.  </reponse>
           </question>


 </category>



          <category>

          <libelle>Real estate</libelle>
          <cat_id>IM</cat_id>
          <question>
          <reference>I1</reference>
          <libelle>What is the investment strategy? </libelle>
          <reponse>
          The investment strategy is the purchase of real estate in Berlin in the first instance and in the second instance in the heart of urban centers of national or regional capitals in the Eurozone.

          The main criterion is the potential value of these properties in 10 years. The choice of the address, its centrality and the quality of the building are essential.

          The tax environment is the second important criterion. Currently the investments are made in BERLIN, the German capital.

          The rental yield is a secondary criterion that has no impact on the reference value of the Berlcoin since it is calculated from the VDP Immobilienpreisindex.
          </reponse>

          </question>

          <question>
          <reference>I2</reference>
          <libelle>What is the management strategy?</libelle>
          <reponse>
          We have an ethical management of our relations with tenants: no early lease termination, no over-renting, no over-billing for services, setting the rent in the average of those practiced in the neighborhood, no carrying out of works intended to increase the rent, no rent increase outside the legal framework or not provided for in the lease.
          </reponse>

          </question>


 	         <question>
 	         <reference>I3</reference>
 	         <libelle>How are the properties selected?</libelle>
 	         <reponse>The management company continuously analyzes the market: when a purchase order is transmitted, it extracts 4 to 5 properties corresponding to the criteria defined in the purchase order, they are visited and the prices are validated.

 	         A specialized buyer of selects a property from this list after a control visit and confirms the purchase order.
  	 </reponse>

 	         </question>

  	          <question>
  	          <reference>I4</reference>
  	          <libelle>What are the fees taken by the company that manages Berlcoin when acquiring a property?</libelle>
  	          <reponse>
  	          0%: no fees
  	          </reponse>
  	          </question>

  	          <question>
  	          <reference>I5</reference>
  	          <libelle>What are the fees taken by the property management company when acquiring a property?</libelle>
  	          <reponse>
  	          0%: no fees
  	          </reponse>
  	          </question>

   		         <question>
   		         <reference>I6</reference>
   		         <libelle>Who manages the property?</libelle>
   		         <reponse>For condominiums: the trustee of each condominium property, whose fees vary depending on the contract with the condominium.
   		        The management company for the property is: Fraction Real Estate SAS
   		        </reponse>
   		         </question>
    		 <question>
    		          <reference>I7</reference>
    		          <libelle>Who pays the local taxes?</libelle>
    		          <reponse>The management company, using the rents collected. </reponse>
    		          </question>
    		         <question>
    		          <reference>I8</reference>
    		          <libelle>What impact if rent is unpaid? </libelle>
    		          <reponse>
    		          None: the reference value of the Berlcoin is calculated from the VDP Immobilienpreisindex and not on the rental yield.

    		          We invest in Germany: unpaid rent is very rare because it is a reason for automatic termination of the lease and a registration of the defaulting tenant in the SCHUFA register.  Access to credit will then be difficult for him and will in practice make it almost impossible to conclude another lease.
    		          </reponse>
    		          </question>

     			         <question>
     			         <reference>I9</reference>
     			         <libelle>Who pays for the condominium work?</libelle>
     			         <reponse> In Germany, the works are provisioned on a specific accounting line and integrated into the monthly paid charges. In general, condominiums have financial reserves of several tens of thousands of euros and plan important works.  </reponse>
     			         </question>


      			          <question>
      			          <reference>I10</reference>
      			          <libelle>How are home loans paid off? </libelle>
      			          <reponse>Berlcoin does not use banks to finance the acquisition of property. If, however, there is a loan, it is with the gradual resale of tokens issued but not distributed when the NFT corresponding to the property on which the credit is based is created. Therefore, it is not the investors who pay for the credit and there is never any issuance of new tokens to repay the credits.</reponse>
      			          </question>


                 <question>
                 <reference>I11</reference>
                 <libelle>How is the value of real estate discounted?</libelle>
                 <reponse>
                 By applying the annual rate of change of the house price index for each category of property provided by the association of German banks Verband Deutscher Pfandbriefbanken e V.

                 https://www.pfandbrief.de/site/de/vdp/immobilie/finanzierung_und_markt/vdp-immobilienpreisindex.html
                 </reponse>
                 </question>

                   <question>
                   <reference>I12</reference>
                   <libelle>When is the real estate resold?</libelle>
                   <reponse>
                   In principle, except for cyclically unfavorable market conditions, from the beginning of the tax-free period, currently for investments in Berlin: in the 11th year of ownership.

                   </reponse>
                   </question>

          	         <question>
          	         <reference>I13</reference>
          	         <libelle>How to take advantage of the leverage effect. </libelle>
          	         <reponse>
          	         You can benefit from leverage through term investments by allocating tokens at the end of your contract.
          	         </reponse>
          	         </question>

           	          <question>
           	          <reference>I14</reference>
           	          <libelle>What happens to the rents? </libelle>
           	          <reponse>

           	         They are used first to pay taxes and exceptional expenses if any, but neither current expenses that have already been provided for, nor the repayment of loans (by default we do not use banks).

           	         Then to finance :
           	         - the difference in value between the initial acquisition cost and the real value. (to amortize the notary fees, taxes, restoration fees...)
           	         - forward contracts
           	         - sponsoring contracts

           	         Finally to ensure the liquidity of the market: each month, a purchase order for Berlcoin at the reference value is placed on the decentralized market up to the amount of available rents.

										 And of course, to buy new real estate to increase the overall collateral of Berlcoin.

           	          </reponse>
           	          </question>

  			         </category>
  			         <category>

  			         <libelle>Taxation</libelle>
  			         <cat_id>FT1</cat_id>
  			         <question>
  			         <reference>FT1</reference>
  			         <libelle>What is the taxation of berlcoins? </libelle>
  			         <reponse>In France 30% of the profits (difference in price between purchase and sale) on a transfer of berlcoin if converted into euros
  			         In Germany 28% of the profits (difference in price between purchase and sale) on a transfer of berlcoin
  			         </reponse>
  			         </question>

   			          <question>
   			          <reference>FT2</reference>
   			          <libelle>What is the taxation of real estate? </libelle>
   			          <reponse>We optimize the tax impact by selecting real estate in a stable and favorable tax environment.
   			          Currently investments are made in Berlin:
   			          - no real estate capital gains tax after 10 years of ownership,
   			          - average tax deduction of 10%,
   			          - no CGS-RDS,
   			          - very low property tax,
   			          - depreciation of 2% of the property price,
   			          - no diagnostic fees,
   			          - no unpaid insurance,
   			          - very tense area with very low vacancy risk...
   			          </reponse>
   			          </question>
   			         <question>
   			          <reference>FT3</reference>
   			          <libelle>Are berlcoins to be included in the calculation of the IFI?</libelle>
   			          <reponse>No, berlcoins are tokens that fall within the tax framework of financial assets that are not included in the calculation of the IFI (Impôt sur la fortune immobilière).
   			          </reponse>
   			          </question>

              </category>
              <category>

              <libelle>Guarantee of value</libelle>
              <cat_id>GV</cat_id>
              <question>
              <reference>V1</reference>
              <libelle>Can the reference value go down</libelle>
              <reponse>Yes, in case of a crisis in the real estate market, if the VDP Immobilienpreisindex is negative. (This already happened 18 years ago, in 2004) </reponse>
              </question>

                <question>
                <reference>V2</reference>
                <libelle>When can a warranty be exercised?</libelle>
                <reponse> You can exercise a collateral take in case of a liquidity crisis in the primary market if the resale value in the decentralized markets of the tokens were to fall below the reference value. </reponse>
                </question>

           <question>
           <reference>V3</reference>
           <libelle>How can a guarantee be exercised? </libelle>
           <reponse>You choose a property from the list of assets and deposit the corresponding number of tokens into an escrow account via a smart contract.
          Fraction Real Estate has 120 days to buy back your tokens at the reference price. After this period, the smart contract transfers ownership of the NFT to you and your tokens to Fraction Real Estate.

          You can then :
          - keep the NFT
          - resell the NFT,
          - resell the property and transfer the NFT to the buyer (who can then destroy it)
          - ask the notary to register your name in the land register and destroy the NFT.

					The property securing the NFT cards can only be used as collateral after the expiry of their validity date.
					Cardholders have priority over these properties.

           </reponse>
           </question>



					 <question>
			   			          <reference>V5</reference>
			   			          <libelle>Is there a legal separation between the company that manages the berlcoin and the company that manages the assets?</libelle>
			   			          <reponse>Yes, there is no cross-shareholding: a problem with the company issuing the tokens will not affect the guarantee. </reponse>
			   			          </question>
													 <question>
			     <reference>V6</reference>
			 			 <libelle>Is there reciprocal control between the company managing the berlcoin and the company managing the assets?</libelle>
			 			 <reponse>Yes, the boards of directors of each company include a representative from the other company.
			 			 </reponse>
			 			 </question>


          </category>

		</questions>
`,
		xml_de: ``,









































































		xml_fr: `
      <?xml version="1.0"?>

      <questions>

      <titre>Les réponses à vos questions
      </titre>

<soustitre>Pour toute information complémentaire, contactez-nous par email à info@berlcoin.fr ou par téléphone au +33 1 xxx xxx xxx
</soustitre>
<illustration>https://media.berlcoin.fr/5240324.jpg</illustration>



<texte>
</texte>
      <category>

      <libelle>Blockchain</libelle>
      <cat_id>BC</cat_id>


      <question>
      <reference>B1</reference>
      <libelle>Qu'est-ce qu'une blockchain ?</libelle>
      <reponse>Une blockchain est un registre public décentralisé attestant de qui détient quoi.
      Les blockchains permettent de stocker et d'échanger de la valeur sur internet sans intermédiaire centralisé.
      Elles sont le moteur technologique des cryptomonnaies, du Web Decentralisé et de la finance décentralisée.
      </reponse>
      </question>
      <question>
      <libelle>Qu'est-ce que Stellar ?</libelle>
      <reference>B11</reference>
      <reponse>Stellar est à la fois un protocole d'échanges décentralisé et un protocole de paiement décentralisé basé sur la blockchain. Il permet de faire facilement et à moindre coût des passerelles entre les tokens et les différentes monnaies FIAT (euro, dollar, yuan...).</reponse>
      </question>


      <question>
      <libelle>Qu'est-ce qu'Ethereum ?</libelle>
      <reference>B2</reference>
      <reponse> Ethereum est un protocole d'échanges décentralisé basé sur la blockchain permettant la création par les utilisateurs de contrats intelligents (smart contrat).</reponse>
      </question>


      <question>
      <reference>B3</reference>
      <libelle>Qu'est-ce qu'un actif numérique ?</libelle>
      <reponse>
      Un actif numérique est un bien immatériel de format binaire incluant un droit de propriété.
      </reponse>
      </question>


      <question>
      <reference>B4</reference>
      <libelle>Qu'est-ce qu'un token ?</libelle>
      <reponse>
      Un token désigne un actif numérique transférable entre deux parties sans avoir besoin de l'autorisation d'un tiers. Il s'agit d'un instrument de base de la finance décentralisée.
      </reponse>
      </question>

      <question>
      <reference>B5</reference>
      <libelle>Un token est-il une cryptomonnaie ?</libelle>
      <reponse>
      Non car un token n'a pas sa propre Blockchain mais il s'appuie sur une cryptomonnaie pour valider ses transactions.
      </reponse>
      </question>

      <question>
      <reference>B6</reference>
      <libelle>Qu'est-ce qu'un berlcoin ?</libelle>
      <reponse>
      Un berlcoin est un token sur la blockchain Stellar.
      </reponse>
      </question>


      <question>
      <reference>B14</reference>
      <libelle>Qu'est-ce qu'un Lumens ?</libelle>
      <reponse>
      Le Lumens est la cryptomonnaie de la blockchain Stellar, elle sert à payer les frais de transaction.
      </reponse>
      </question>

            <question>
            <reference>B12</reference>
            <libelle>Qu'est-ce qu'un ether ?</libelle>
            <reponse>
            L'Ether est la cryptomonnaie de la blockchain Ethereum, elle sert à payer les frais de transaction sur le réseau Ethereum qui est utilisé par certaines places de marchés populaires.
            </reponse>
            </question>

            <question>
            <reference>B13</reference>
            <libelle>Qu'est-ce qu'un fcEURO ?</libelle>
            <reponse>
            Un fcEURO est un token de la blockchain Stellar qui est émis par la société Fraction.re. C'est une unité de compte utilisée dans l'écosytème Fraction.r dont fait partie le Berlcoin.  Son cours est fixe de 1 fcEURO = 1 EURO. Ce token ne peut servir qu'à acheter ou vendre des tokens Berlcoins ou des tokens de l'écosytème Fraction.re
            </reponse>
            </question>




      <question>
      <reference>B7</reference>
      <libelle>Qu'est-ce qu'un NFT ?</libelle>
      <reponse>
      Un NFT « Non fungible token » est un token unique non fongible, donc non découpable.
      Les NFT sont des éléments cryptographiques  sur la blockchain avec des codes d'identification uniques et des métadonnées (auteur, signature, date, type...) qui les distinguent les uns des autres.
      Chaque NFT correspond à un bien immobilier spécifique.
      </reponse>
      </question>

      <question>
      <reference>B8</reference>
      <libelle>Qu'est-ce qu'un stablecoin ?</libelle>
      <reponse>
      Un stablecoin est un actif numérique qui réplique la valeur d’un autre bien, d'une monnaie ou d’une référence externe.
      Son prix peut être ancré au prix d'actif comme l’or, le pétrole, le gaz, les diamants ou à la valeur d’une devise émise par un gouvernement comme le dollar, l’euro, le yuan, etc...
      Le fcEURO peut être considéré comme un stablecoin repliquant la valeur des euros et le BERLCOIN comme un stablecoin repliquant la valeur d'actifs immobiliers.
      </reponse>
      </question>
      <question>
      <reference>B9</reference>
      <libelle>Qu'est-ce qu'un Smart Contrat ?</libelle>
      <reponse>
      Les contrats intelligents (Smart-Contrat) sont basés sur un protocole informatique permettant de vérifier ou de mettre en application un contrat mutuel. Ils sont déployés et consultables publiquement dans une blockchain.
      </reponse>
      </question>
      <question>
      <reference>B10</reference>
      <libelle>Qu'est-ce qu'un Wallet ?</libelle>
      <reponse>
      Un wallet (portefeuille en anglais) est un procédé de stockage sécurisé, physique ou numérique, de tokens, cartes bancaires ou cryptomonnaies.
      Le Berlcoin Wallet est un des portefeuilles que vous pouvez utiliser et qui est spécifiquement conçu pour le Berlcoin.  Mais ce n'est pas une obligation, vous pouvez utiliser n'importe quel Wallet.
      </reponse>
      </question>
			<question>
			<reference>B11</reference>
 <libelle>Qu'est ce qu'une Fraction  ?</libelle>
 <reponse>
 	Une fraction est une part d'un bien immobilier dans un contrat de droit français de cession de revenus futur. Le token Berlcoin est la représentation dans la blockchain de cette fraction.
 	La valeur d'une fraction est identique à la valeur d'un Berlcoin.
 </reponse>
			</question>

			<question>
			<reference>B15</reference>
 <libelle>Suis-je obligé d'utiliser la blockchain pour investir ?</libelle>
 <reponse>
 Non, vous pouvez renseigner les informations d'identification KYC/AML sur   https://wallet.fraction.re/identity  et investir sur le marché primaire.
 Votre investissement sera bien sûr inscrit dans la blockchain et vous recevrez vos clés pour vérifier, mais vous n'êtes pas obligé de réclamer vos tokens.  Si vous désirez réinvestir, vous pourrez utiliser la clé publique, mais aussi votre e-mail ou votre téléphone pour vous identifiez.
 Pour revendre, il suffit d'utiliser le formulaire à l'adresse suivante https://coffee.berlcoin.berlin/market.html.
 Par contre, pour utiliser les marchés décentralisées, les AMM ou les placements dans les Liquidity Pool, le sponsoring ou plus généralement tous les outils propres à la blockchain, vous devrez utiliser la blockchain.
 </reponse>
			</question>


      </category>

      <category>

      <libelle>Fonctionnement</libelle>
      <cat_id>FT</cat_id>
      <question>
      <reference>F1</reference>
      <libelle>Qu'est-ce que la plateforme berlcoin ?</libelle>
      <reponse>
      Berlcoin.berlin est une plateforme spécialisée dans l’immobilier. Elle permet aux utilisateurs d’investir de manière mutualisée dans des biens immobiliers et utilise la blockchain et les tokens pour sécuriser les investissements.

      Le berlcoin est l'unité de compte utilisée pour définir la part détenue par chaque investisseur.

      Le principe de fonctionnement consiste à créer un token non fongible (NFT) pour chaque bien immobilier immobilier et des tokens berlcoin garantis par ces NFT.

      L'ensemble des NFT garantissent la valeur des tokens berlcoin.

      </reponse>
      </question>

      <question>
      <reference>F2</reference>
      <libelle>Comment est déterminée la valeur de référence du berlcoin ?</libelle>
      <reponse>
Avec le dernier index publié par l'association des banques allemandes (https://www.pfandbrief.de/site/de/vdp/immobilie/finanzierung_und_markt/vdp-immobilienpreisindex.html).
	Prendre la colonne Selbst genutztes Wohneigentum > Eigentums-wohnungen > Condominiums. ( Colonne E)
	Prendre la ligne correspond au dernier trimestre, la valeur de référence au 1er jour du trimestre suivant est ce chiffre multiplié par 0,37387.
	L'index de croissance est celui de la colonne H, calculé en prenant la valeur de la colonne E - valeur de la colonne E  du même trimestre l'année précédente ) divisé par valeur de la colonne E  du même trimestre l'année précédente.

	Au 3eme trimestre 2021, l'indice est de 267,5.  Multiplié par 0.37387, le Berlcoin vaut 100 euros le 1 octobre 2021, l'indice de croissance est de 10% ( colonne H)
	Au 4eme trimestre 2021, l'indice est de 273,1.  Multiplié par 0.37387, le Berlcoin vaut 102,12 euros le 1 janvier 2022, l'indice de croissance est de 11.5% ( colonne H)
	Au 1er  trimestre 2022, l'indice est de 282,7.  Multiplié par 0.37387, le Berlcoin vaut 105,69 euros le 1 avril 2022, l'indice de croissance est de 13.3% ( colonne H)
	      </reponse>
      </question>

      <question>
      <reference>F3</reference>
      <libelle>Comment est déterminé le prix du berlcoin ?</libelle>
      <reponse>
      berlcoin est un token qui peut être acheté et vendu sur des places de marché : son prix est donc déterminé en fonction de l'offre et de la demande.
      </reponse>
      </question>

      <question>
      <reference>F4</reference>
      <libelle>Comment est calculée la valeur d'un NFT en berlcoin ?</libelle>
      <reponse>
      Lors de sa création, la valeur d'un NFT correspond à sa valeur de possession durant 10 ans: les biens immobiliers sont destinés à être revendus lors de la 11eme année et le NFT est alors détruit.

      La valeur du NFT est calculée en additionnant le prix d'acquisition, les taxes, les honoraires du notaire, les charges prévisionnelles pour les 10 ans de possession et une provision de 250 euros par m2 pour une remise en état avant revente. Si nécessaire, s'ajoutent des frais d'intermédiaires (agent immobilier, avocat, expert...) ou de rénovation et d'achat de meubles pour la mise en location.

      Ce montant est divisé par la valeur de référence du berlcoin au moment de la création du NFT: on obtient ainsi le nombre de berlcoins garantis par ce NFT.
      </reponse>
      </question>


      <question>
      <reference>F5</reference>
      <libelle>Comment est calculée la valeur d'un NFT en euros ?</libelle>
      <reponse>
      Il s'agit de la valeur initiale du NFT actualisée en appliquant la variation de croissance calculée avec le VDP Immobilienpreisindex.
      </reponse>
      </question>


      <question>
      <reference>F6</reference>
      <libelle>La garantie d'un NFT en berlcoin est-elle fixe ?</libelle>
      <reponse>
      Oui, lors de la création du NFT, le nombre de berlcoins correspondant à sa valeur est fixé de facon définitive : il n'y a donc aucun risque de dilution de la garantie.
      </reponse>
      </question>


      <question>
      <reference>F8</reference>
      <libelle>Puis-je revendre mes berlcoins quand je le désire ?</libelle>
      <reponse>
      Oui pour ceux qui ne sont pas intégrés dans un contrat de placement à terme. Si vos berlcoins sont placés dans un contrat à terme, ils ne peuvent être revendus qu'une fois le terme échu.
      </reponse>
      </question>


      <question>
      <reference>F11</reference>
      <libelle>Où peut-on acheter et vendre des berlcoins à prix garanti  ?</libelle>
      <reponse>
      Sur notre place de marché (le marché primaire ou place de marché à prix garanti). Le prix est fixé en prenant la valeur totale du portefeuille de NFT divisée par le nombre de tokens émis.
      </reponse>
      </question>


      <question>
      <reference>F12</reference>
      <libelle>Où peut-on acheter et vendre des berlcoins à prix de marché  ?</libelle>
      <reponse>
      Sur n'importe quelle place de marché (les marchés décentralisées) acceptant les tokens sur la blockchain stellar ou  à la norme ERC20 sur la blockchain ethereum. Et sur les AMM (Automated Market Maker) contre d'autres tokens ou cryptomonnaies.
      </reponse>
      </question>


      <question>
      <reference>F13</reference>
      <libelle>Dispose-t'on d'un droit de préemption sur un NFT lors de la vente d'un bien  ?</libelle>
      <reponse>
      Oui pour les contrats patrimoines liés à un bien. Pour les autres, les détenteurs de berlcoins seront bien évidemment prioritaires.
      </reponse>
      </question>


      <question>
      <reference>F14</reference>
      <libelle>Peut-on acheter et vendre des berlcoins en cryptomonnaies ?</libelle>
      <reponse>
      Oui, sur n'importe quelle place de marché acceptant les tokens sur la blockchain stellar ou  à la norme ERC20 sur la blockchain ethereum. Le cours est celui du prix de marché et non de la valeur de référence.
      Notre place de marché à prix garanti est exclusivement en euros.
      </reponse>
      </question>

      <question>
      <reference>F15</reference>
      <libelle>Peut-on conserver ses berlcoins dans un wallet ou dans un coffre-fort  ?</libelle>
      <reponse>
      Oui, les berlcoins sont des tokens que vous pouvez les transférer facilement.
      Attention toutefois, vous ne pourrez pas les revendre sur la place de marché à prix garanti si ceux-ci ont été transférés vers une adresse sur laquelle nous n'avons pas pu faire des contrôles KYC.
      </reponse>
      </question>

            <question>
            <reference>F16</reference>
            <libelle>Peut-on convertir ses Berlcoins pour le réseau ethereum  ?</libelle>
            <reponse>
            Oui, les berlcoins sont des tokens stellar mais vous pouvez acheter des Berlcoins à la norme ERC20 en échange d'un Berlcoin natif.
            Ces berlcoins natifs sont alors placés sur un compte de séquestre, la parité de 1:1 est donc garantie.
            Les frais de gas sont sur les deux réseaux : une transaction stellar + une transaction ethereum.
            </reponse>
            </question>



 		      <question>
 		      <reference>F17</reference>
 		      <libelle>Qu'est ce que le marché primaire ?</libelle>
 		      <reponse>
  	C'est la place de marché utilisée par le gestionnaire immobilier pour vendre les berlcoins nouvellement émis lors de l'acquisition d'un bien immobilier ou pour la vente de berlcoin non distribué ou retourné.
  	Le prix de vente est fixe, il correspond à la valeur de référence au jour de l'ordre de vente.
    </reponse>
 		      </question>

 	<question>
 	      <reference>F18</reference>
 <libelle>Qu'est ce que le marché décentralisée ?</libelle>
 <reponse>
 C'est la place de marché utilisée par les internautes pour l'achat et la vente de Berlin.
Le prix de vente est libre, il est fixé par l'offre et la demande. Chaque acteur peut passer un ordre de vente ou un ordre d'achat au prix qu'il souhaite, cet ordre est inscrit dans le carnet d'ordres et ces ordres sont exécutés chronologiquement.
L'ordre est mis en attente tant qu'il n'a pas trouvé preneur et peut être modifié ou annuler tant qu'il n'a pas été exécuté. Le prix est donc garanti, mais pas le délai.
 			</reponse>
 	</question>
 	<question>
 	      <reference>F19</reference>
 <libelle>Qu'est ce qu'un marché automatique (AMM) ?</libelle>
 <reponse>
 C'est la place de marché utilisée par les internautes pour l'achat et la vente immédiate de Berlcoin.
Le prix de d'achat et de vente est calculé par un smart-contract en fonction des liquidités dans Liquidity Pool. Il n'y a donc aucune garanti de prix, par contre l'ordre est exécuté immédiatement.
Le coût d'utilisation des AMM est de 0.3%
		</reponse>
 	</question>

	<question>
 	      <reference>F20</reference>
 <libelle>Comment revendre sans utiliser la blockchain</libelle>
 <reponse>
Vous pouvez utiliser le marché primaire pour revendre sans passer par la blockchain à condition que vous ayez investi initialement via le marché primaire et que vous n'avez pas transféré vos tokens.
Il suffit d'aller sur https://berlcoin.market > marché primaire > vente fraction.
Vous recevrez un avenant à votre contrat, les berlcoins seront revendus sur le marché primaire et vous recevrez un virement bancaire sur le compte bancaire que vous avez utilisé pour l'achat.
		</reponse>
 	</question>



      </category>


      <category>

      <libelle>Société</libelle>
      <cat_id>SO</cat_id>
      <question>
      <reference>S1</reference>
      <libelle>Quelle société gère le berlcoin ?</libelle>
      <reponse> Fraction.re SAS  est a société qui gère le berlcoin. Elle a son siège au 56, rue Monge 75005 Paris, France. Fraction.re est une Société par Actions Simplifiée à capital variable de 250 000 euros minimum enregistrée le 30/12/2021 au Registre du Commerce et des Sociétés de Paris sous le numéro 908 680 002.
      </reponse>
      </question>

      <question>
      <reference>S2</reference>
      <libelle>Quelle société gére les biens immobiliers</libelle>
      <reponse>
			SCI CONDE 52 est la société qui posséde les 3 premiers biens mis en garanti initialement. C'est une société civile immobilière qui à son siège 2Ter rue de Bapaume 59000 Lille, France. Elle est enregistré au Registre du Commerce et des Sociétés de Lille sous le numéro 494 556 863 depuis 2007.

		Fraction Real Estate SAS est la société qui administre les biens, elle a son siège au 56, rue Monge 75005 Paris, France, c'est une Société par Actions Simplifiée à capital variable enregistrée le 01/04/2022 au Registre du Commerce et des Sociétés de Paris sous le numéro 911 774 206.

			</reponse>
      </question>

      </category>

      <category>

      <libelle>Gestion</libelle>
      <cat_id>GT</cat_id>

      <question>
      <reference>G1</reference>
      <libelle>Comment se rémunère Fraction.re SAS ?</libelle>
      <reponse>
      Cette société est le premier investisseur pour chaque bien immobilier acheté.

			Sa part d'investissement est faite grace à ses fonds propres. Elle reçoit un nombre de tokens correspondant à ces investissements au même titre et au même prix que n'importe quel autre investisseur.

			A la fin d'un cycle de possession, la société revend le bien immobilier et rachete à la valeur de référence le nombre de Berlcoin garanti.

Nous avons aussi deux sortes de revenu :

Nous conservons 1% de tokens en circulation chaque année, sous réserve qu’il y ait suffisamment de token non distribués. (nous n'émettons jamais de nouveaux tokens sans achat d’un collatéral immobilier)

Nous bénéficions des revenus générés par la finance décentralisée (Defi), comme les AMM par exemple.

Pour simplifier : l’immobilier pour le patrimoine, la défi pour les flux de revenus.

C'est ce qui fait la force du modéle économique du Berlcoin : Les intérêts de tous les acteurs sont strictement identiques.

      </reponse>

			</question>


			      <question>
			      <reference>G12</reference>
			      <libelle>Quels risques et bénéfices prend Fraction.re SAS  ?</libelle>
			      <reponse>
						A la fin d'un cycle de possession, la société revend le bien immobilier et rachète à la valeur de référence le nombre de Berlcoin garantis par ce bien.

 Si le prix de revente réel est supérieur au prix du nombre de Berlcoin que doit racheter la société pour sortir ce bien du portefeuille de garantie, elle fait du bénéfice.

 Inversement, si le prix de revente réel est inférieur, elle perd de l'argent. Donc, pour être certain de vendre à un prix qui lui fasse gagner de l'argent, elle fait très attention à ce qui est acheté.

 C'est une garantie très forte pour les investisseurs, car le portefeuille de biens a toujours une valeur au minimum égale à la valeur des Berlcoins.

 C'est ce qui fait aussi la force du modèle économique du Berlcoin : les intérêts de certains acteurs renforcent la protection des autres.
			      </reponse>




      </question>


			<question>
      <reference>G13</reference>
      <libelle>Quelle est l'origine du 1% conservé annuellement  ?</libelle>
      <reponse>Nous conservons 1% de tokens en circulation chaque année, sous réserve qu’il y ait suffisamment de token non distribués. (nous n'émettons jamais de nouveaux tokens sans achat d’un collatéral immobilier)
			Ce sont des tokens qui n'ont pas été distribué après leurs émissions.
Lorsqu'une partie du financement vient des revenus liés aux biens eux-mêmes, indépendament de leurs sources, les tokens émis correspondant à ce financement ne sont pas distribués puisque leur financement reste interne à la société. Pour autant, ils constituent une réserve qui renforce les actifs de la société.

Ce 1% est extrait dans ce fond de réserve (s'il y en a de disponible), et les berlcoins peuvent donc être revendus pour notre propre compte.


      </reponse>
      </question>


      <question>
      <reference>G2</reference>
      <libelle>Quels sont les frais de gestion locative ?</libelle>
      <reponse>Aucun, ils sont intégralement pris en charge par la société qui gére le Berlcoin
      </reponse>
      </question>

      <question>
      <reference>G3</reference>
      <libelle>Quels sont les coûts d'assurances locatives ?</libelle>
      <reponse>Il n'y a pas d'assurances vacances locatives ou impayés car ce risque est très faible sur les immeubles sélectionnés.
      </reponse>
      </question>

      <question>
      <reference>G4</reference>
      <libelle>Quels sont les frais pour acheter ou vendre des tokens berlcoin ?</libelle>
      <reponse>Des frais de gas pour l'inscription d'une transaction dans la blockchain sont pris par le réseau ou les mineurs. Ces frais varient  selon le moment et la vitesse d'enregistrement de la transaction.     Ils sont inférieur à 1 centime. Lors d'échange automatique sur un AMMs les frais sont de 0.03% du token échangé.
      </reponse>
      </question>

      <question>
      <reference>G5</reference>
      <libelle>Quels sont les frais prélevés par  lors de la souscription de contrats à terme ?</libelle>
      <reponse>
			En Lumens : aucun
			Par virement SEPA : aucun
      Par carte bancaire ou paypal : entre 1.6% et 3.4% selon la carte ou la banque
      </reponse>
      </question>
      <question>
      <reference>G11</reference>
      <libelle>Quels sont les frais prélevés par  lors de la vente de berlcoins ?</libelle>
      <reponse>
      Frais de gas ( entre 0.01 et 0.03 euros ) sur le marché primaire.
      Frais de gas ( entre 0.01 et 0.03 euros ) sur les marchés décentralisées non automatisés.
      Sur les AMM (Automated Market Maker) Stellar : 0.3%
      Sur le marché uniswap en ether  : 0.3%
      </reponse>
      </question>

      <question>
      <reference>G6</reference>
      <libelle>Quel rendement peut-on  obtenir ?</libelle>
      <reponse>
La valeur de référence du Berlcoin est calculé en fonction du VDP Immobilienpreisindex	: 6.1% en 2022, 9.6% en moyenne sur les 5 dernières années.

A cela peut s'ajouter l'obtention gratuite de Berlcoin via :

- Les contrat à terme :  2 ans +1.9%, 3 ans +5.6%, 5 ans +14.4%, 7 ans +28.5%, 10 ans +46% à 55%

- Finance décentralisée : 0.3% des transactions

			- Sponsoring : 2% pour le sponsor, 1% pour le filleul + le pourcentage du contrat à de terme.
      </reponse>
      </question>

      <question>
      <reference>G7</reference>
      <libelle>Peut-on investir via une SCI ou une société</libelle>
      <reponse>Oui, à condition que les bénéficiaires effectifs soient identifiables.</reponse>
      </question>



      <question>
      <reference>G8</reference>
      <libelle>Y a-t’il une émission  de tokens gratuits pour les dirigeants ?</libelle>
      <reponse>Non                                                  </reponse>
      </question>


      <question>
      <reference>G9</reference>
      <libelle>Quelle est la durée de conservation préconisée des tokens   ?</libelle>
      <reponse> Aucune, l’avantage de la tokenisation est justement de pouvoir acheter et vendre sans délai avec des frais réduits.                   </reponse>
      </question>


      <question>
      <reference>G10</reference>
      <libelle>Quelle est la durée préconisée pour les placements à terme  ?</libelle>
      <reponse> Plus le contrat est long, plus il est générateur de tokens à son terme. Le contrat à 10 ans est donc le placement le plus intéressant financièrement.              </reponse>
      </question>



      </category>

      <category>

      <libelle>Immobilier</libelle>
      <cat_id>IM</cat_id>
      <question>
      <reference>I1</reference>
      <libelle>Quelle est la stratégie d'investissement ?</libelle>
      <reponse>
      La stratégie d'investissement est l'achat de biens immobiliers à Berlin dans un premier temps et dans un second temps au coeur des centres urbains de capitales nationales ou régionales dans la zone euro.

      Le potentiel de valorisation de ces biens à 10 ans est le critère principal. Le choix de l'adresse, sa centralité et la qualité du bâtiment sont primordiaux.

      L'environnement fiscal est le second critère important. Actuellement les investissements sont réalisés à BERLIN, la capitale allemande.

      Le rendement locatif est un critère secondaire qui n'a pas d'impact sur la valeur de référence du Berlcoin puisque celle-ci est calculée à partir du VDP Immobilienpreisindex.
      </reponse>

      </question>

      <question>
      <reference>I2</reference>
      <libelle>Quelle est la stratégie de gestion ?</libelle>
      <reponse>
      Nous avons une gestion éthique de nos relations avec les locataires:  aucune résiliation de bail anticipé, pas de surloyer, aucune surfacturation de prestation, fixation du loyer dans la moyenne de ceux pratiqués dans le quartier, pas de réalisation de travaux destinés à augmenter le loyer, pas d'augmentation du loyer hors cadre légal ou non prévue dans le bail.
      </reponse>

      </question>


      <question>
      <reference>I3</reference>
      <libelle>Comment sont sélectionnés les biens immobiliers ?</libelle>
      <reponse>La société de gestion analyse le marché en continu: lorsque qu'un ordre d'achat est transmis, celle-ci extrait 4 à 5 biens correspondant aux critères définis dans l'ordre d'achat, ils sont visités et les prix sont validés.

      Un acheteur spécialisé de  selectionne un bien parmi cette liste après une visite de contrôle et confirme l'ordre d'achat.
      </reponse>
      </question>

      <question>
      <reference>I4</reference>
      <libelle>Quel sont les frais pris par la société qui gére le Berlcoin lors de l'acquistion d'un bien  ?</libelle>
      <reponse>
  0% : aucun frais
      </reponse>
      </question>
        <question>
      <reference>I5</reference>
      <libelle>Quel sont les frais pris par la société qui gére les biens lors de l'acquistion d'un bien  ?</libelle>
      <reponse>
  0% : aucun frais
      </reponse>
      </question>

      <question>
      <reference>I6</reference>
      <libelle>Qui gère les biens ?</libelle>
      <reponse>Pour les copropriétés: le syndic de chaque bien en copropriété, dont les émoluments varient selon le contrat passé avec la copropriété.
La société de gestion du parc immobilier est : Fraction Real Estate SAS
			</reponse>
      </question>

      <question>
      <reference>I7</reference>
      <libelle>Qui paye les taxes locales ?</libelle>
      <reponse>La société de gestion, en utilisant les loyers perçus. </reponse>
      </question>

      <question>
      <reference>I8</reference>
      <libelle>Quel impact en cas d'impayés de loyers ?</libelle>
      <reponse>
    Aucun : la valeur de référence du Berlcoin est calculée à partir du VDP Immobilienpreisindex et non sur le rendement locatif.

      Nous investissons en Allemagne: les impayés sont très rares car c'est un motif de résilation automatique du bail et une inscription du locataire défaillant au registre SCHUFA.            L'acces au crédit lui sera ensuite difficile et rendra dans la pratique presque impossible la conclusion d'un autre bail.
      </reponse>
      </question>



      <question>
      <reference>I9</reference>
      <libelle>Qui paye les travaux de copropriété ?</libelle>
      <reponse>En Allemagne, les travaux sont provisionnés sur une ligne comptable spécifique et intégrés aux charges payées mensuellement. En régle générale les copropriétés disposent de réserves financière de plusieurs dizaines de milliers d'euros et planifient les travaux importants.  </reponse>
      </question>



      <question>
      <reference>I10</reference>
      <libelle>Comment sont remboursés les emprunts immobiliers ?</libelle>
      <reponse>Berlcoin n'a pas recours aux banques pour financer l'aquisition des biens. Si toutefois, il y a un emprunt, c'est avec la revente progressive des tokens émis mais non distribués lors de la création du NFT correspondant au bien sur lequel porte le crédit. Ce ne sont donc pas les investisseurs qui payent le crédit et il n'y a jamais d'émission de nouveaux tokens pour rembourser les crédits.</reponse>
      </question>



      <question>
      <reference>I11</reference>
      <libelle>Comment est actualisée la valeur d'un bien immobilier ?</libelle>
      <reponse>
      En applicant le taux de variation annuel de l'indice des prix des logements pour chaque catégorie de bien fournis par l'association des banques allemandes Verband Deutscher Pfandbriefbanken e V.

      https://www.pfandbrief.de/site/de/vdp/immobilie/finanzierung_und_markt/vdp-immobilienpreisindex.html
      </reponse>
      </question>


      <question>
      <reference>I12</reference>
      <libelle>Quand sont revendus les biens immobiliers  ?</libelle>
      <reponse>
      En principe, sauf conditions conjoncturellement défavorables du marché, dés le début de la période de franchise fiscale, actuellement pour les investissements à Berlin : lors de la 11eme année de possession.

      </reponse>
      </question>


      <question>
      <reference>I13</reference>
      <libelle>Comment bénéficier de l'effet de levier  ?</libelle>
      <reponse>
    Vous pouvez bénéficier d'un effet de levier grâce aux placements à terme par l'attribution de tokens à la fin de votre contrat.
      </reponse>
      </question>


            <question>
            <reference>I14</reference>
            <libelle>Que deviennent les loyers  ?</libelle>
            <reponse>

Ils servent à payer en premier les impôts et les charges exceptionnelles s'il y a lieu, mais ni les charges courantes qui ont été déjà provisionnées, ni le remboursement des emprunts (par défaut nous n'avons pas recours aux banques).

Ensuite à financer :
- la différence de valeur entre le coût d'acquistion initiale et la valeur réelle. ( pour amortir les frais de notaire, taxe, frais de remise en état...)
- les contrats à terme
- les contats de sponsoring

Enfin à assurer la liquidité du marché : chaque mois, un ordre d'achat de Berlcoin à la valeur de référence est placé sur le marché décentralisée et sur le marché primaire à concurrence du montant des loyers disponibles.

Et bien sur, à acheter de nouveaux biens immobiliers pour augmenter la garantie globale du Berlcoin.

            </reponse>
            </question>



      </category>
      <category>

      <libelle>Fiscalité</libelle>
      <cat_id>FT1</cat_id>
      <question>
      <reference>FT1</reference>
      <libelle>Quelle est la fiscalité des berlcoins ?</libelle>
      <reponse>En France 30% des bénéfices (différence de prix entre achat et vente) lors d'une cession de berlcoin s'ils sont convertis en euros
      En Allemagne 28% des bénéfices (différence de prix entre achat et vente) lors d'une cession de berlcoin
      </reponse>
      </question>

      <question>
      <reference>FT2</reference>
      <libelle>Quelle est la fiscalité de l'immobilier ?</libelle>
      <reponse>Nous optimisons l'impact fiscal par une sélection de biens immobiliers dans un environnement fiscal stable et propice.
      Actuellement les investissements sont réalisés à Berlin :
      - aucune taxe sur la plus-value immobilière après 10 ans de possession,
      - prélévement fiscal moyen de 10%,
      - pas de CGS-RDS,
      - taxe foncière très faible,
      - amortissement de 2% du prix du bien,
      - pas de frais de diagnostic,
      - pas d'assurance impayés,
      - zone très tendue avec risque de vacance très faible...
      </reponse>
      </question>

      <question>
      <reference>FT3</reference>
      <libelle>Les berlcoin sont-il à intégrer dans le calcul de l'IFI ?</libelle>
      <reponse>Non, les berlcoins sont des tokens qui rentrent dans le cadre fiscal des actifs financiers qui ne sont pas intégrés dans le calcul de l’IFI (Impôt sur la fortune immobilière).
      </reponse>
      </question>


      </category>
      <category>

      <libelle>Garantie de valeur</libelle>
      <cat_id>GV</cat_id>
      <question>
      <reference>V1</reference>
      <libelle>La valeur de référence peut-elle baisser ?</libelle>
      <reponse>Oui, en cas de crise du marché immobilier,  si le VDP Immobilienpreisindex est négatif. (cela s'est déjà produit il y a 18 ans, en 2004) </reponse>
      </question>


      <question>
      <reference>V2</reference>
      <libelle>Quand peut-on exercer une garantie ?</libelle>
      <reponse> Vous pouvez exercer une prise de garantie en cas de crise de liquidité sur le marché primaire si la valeur de revente sur les marchés décentralisées des tokens venait à être inférieure à la valeur de référence. </reponse>
      </question>



      <question>
      <reference>V3</reference>
      <libelle>Comment peut-on exercer une garantie ?</libelle>
      <reponse>Vous choisissez un bien dans la liste des actifs et déposez le nombre de tokens correspondant sur un compte de séquestre via un smart contrat.
       Fraction Real Estate dispose de 120 jours pour vous racheter vos tokens au prix de référence. Passé ce délai, le smart contrat vous transfère la propriété du NFT et transfère vos tokens à Fraction Real Estate.

			Vous pouvez alors :
			- conserver le NFT
			- revendre le NFT,
			- revendre le bien immobilier et transferer le NFT à l'acheteur (qui peut alors le détruire)
			- demander au notaire d'inscrire votre nom sur le cadastre et de détruire le NFT.

			Les biens qui garantissent les cartes NFT Patrimoines ne peuvent servir à la garantie qu'après l'expiration de leur date de validité.
			Leurs détenteurs sont prioritaires sur ces biens immobiliers.

      </reponse>
      </question>

      <question>
      <reference>V5</reference>
      <libelle>Y a-t’il une séparation juridique  entre la société qui gère le berlcoin et celle qui gère les biens ?</libelle>
      <reponse>Oui, il n’y a pas de participation croisée: un problème avec la société émettrice des tokens n’affectera pas la garantie.   </reponse>
      </question>
      <question>
      <reference>V6</reference>
      <libelle>Y a-t’il un contrôle réciproque entre la société qui gère le berlcoin et celle qui gère les biens ?</libelle>
      <reponse>  Oui, les conseils d’administration de chacune des sociétes accueillent un représentant de l'autre société.
      </reponse>
      </question>

      </category>

      </questions>

      `,

		/*
		<question>
		<reference>V4</reference>
		<libelle>A quelles informations ai-je accès   ?</libelle>
		<reponse>L’ensemble des documents relatifs à l'acquisition des biens mis en garantie sont disponibles sur un cloud accessible à tous les investisseurs.
		Les documents seront signés numériquement et le hash de signature sera intégré à une blockchain publique.

		Les documents intégrés au cloud et à la blockchain :
		Fiche de description du bien
		Contrat de vente du bien
		Factures des intermédiaires ( notaire, avocat, traducteur, agent immobilier… )
		Cadastre
		Documents fiscaux
		Nombre de tokens pour l’exercice de la garantie

		</reponse>
		</question>
		*/

 	}),
 	beforeCreate: function () {},
 	created: function () {},
 	beforeMount: function () {},
 	mounted: function () {
 		this.$i18n.locale = this.$route.params.lang
 		this.langxml();
 	},
 	beforeUpdate: function () {},
 	updated: function () {

 	},
 	beforeDestroy: function () {},
 	destroyed: function () {},
 	computed: {
 		locale() {
 			return this.$i18n.locale;
 		},
 	},
 	methods: {
 		langxml() {
 			if (this.$i18n.locale == "fr") this.selected_xml = this.xml_fr;
 			if (this.$i18n.locale == "en") this.selected_xml = this.xml_en;
 			if (this.$i18n.locale == "de") this.selected_xml = this.xml_de;

 		}
 	},
 	watch: {
 		locale() {
 			this.langxml();
 		},
 	}
 	}
 	</script>

 	<style scoped>
 	</style>
